import { render, staticRenderFns } from "./LeadershipFederation.vue?vue&type=template&id=76667fee&scoped=true"
var script = {}
import style0 from "./LeadershipFederation.vue?vue&type=style&index=0&id=76667fee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76667fee",
  null
  
)

export default component.exports