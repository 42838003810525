 <!-- Experience.vue -->
 <template>
   <div class="flex-container">
     <MS/>
     <BE/>
   </div>
 </template>

 <script>
import MS from './MS.vue'
import BE from './BE.vue'

export default {
   components: {
     MS,
     BE
   }
}
</script>

<style scoped>
.page-wrapper {
   display: flex;
   justify-content: space-between;
   padding: 20px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1em; 
  width: 60%;
  margin: 0 auto;
}
</style>

