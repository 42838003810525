<template>
  <footer class="footer-bar">
    <h3>©2024 Rupal Jain</h3>
    <div class="social-media">
       <a href="https://www.linkedin.com/in/knowrupal/" target="_blank"><img src="../assets/linkedin_icon.svg" alt="LinkedIn"></a> 
       <a href="https://twitter.com/RupalJa5366118" target="_blank"><img src="../assets/x_icon.svg" alt="Twitter"></a>
      <!-- Add more links as needed -->
    </div>
  </footer>
</template>
  
<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}
.social-media img {
   height: 20px;
   width: 20px;
   margin: 5px;
 }
</style>
