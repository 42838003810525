import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import Home from './components/home/Home.vue'
import Experience from './components/experience/Experience.vue'
import Education from './components/education/Education.vue'
import Media from './components/media/Media.vue'
import Certifications from './components/outstanding_achievements/certifications/Certifications.vue'
import Patents from './components/outstanding_achievements/patents/Patents.vue'
import Talks from './components/speaking_engagements/Talks.vue'
import Awards from './components/outstanding_achievements/awards/Awards.vue'
import Recognitions from './components/outstanding_achievements/recognitions/Recognitions.vue'
import GrantFellow from './components/outstanding_achievements/grants_fellowships/GrantFellow.vue'
import Associations from './components/associations/Associations.vue'
import Books from './components/author/books/Books.vue'
import Articles from './components/author/articles/Articles.vue'
import TeamCollab from './components/team_impact_collab/TeamCollab.vue'
import Contact from './components/contact_me/Contact.vue'
import VModal from 'vue-js-modal'
Vue.config.productionTip = false

Vue.use(Router)
Vue.component('Home', Home);
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: true  }  });
const router = new Router({
  routes: [
    {
      path: '',
      name: 'default',
      component: Home
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/experience',
      name: 'experience',
      component: Experience
    },
    {
      path: '/education',
      name: 'education',
      component: Education

    },
    {
      path: '/media',
      name: 'media',
      component: Media
    },
    {
        path: '/certifications',
        name: 'certifications',
        component: Certifications
    },
    {
       path: '/patents',
       name: 'patents',
       component: Patents
    },
    {
       path: '/speaking_engagements',
       name: 'speaking_engagements',
       component: Talks
     },
     {
       path: '/awards',
       name: 'awards',
       component: Awards
     },
      {
        path: '/associations',
        name: 'associations',
        component: Associations
      },
      {path: '/books', name: 'books', component: Books},
      {path: '/articles', name: 'articles', component: Articles},
      {path: '/team_impact', name: 'team_impact', component: TeamCollab},
      {path: '/recognitions', name: 'recognitions', component: Recognitions},
      {path: '/grants', name: 'grants', component: GrantFellow},
      {path: '/contact_me', name: 'contact_me', component: Contact},


    // Other routes go here
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
