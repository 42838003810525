<template>
  <section class="subscription-wrapper">
    <h2 class="subscription-title">Subscribe to my Newsletter</h2>

    <div class="subscription-content">
      <div class="subscription-text">
        <h3 class="subscription-subheading">Stay Updated!</h3>
        <p class="subscription-message">Subscribe to my newsletter and stay updated on the latest </p>
      </div>

      <div class="subscription-form">
        <div class="name-inputs">
          <input class="subscription-input name-input" type="text" placeholder="First Name" v-model="firstName" />
          <input class="subscription-input name-input" type="text" placeholder="Last Name" v-model="lastName" />
        </div>
          <div class="email-inputs">
          <input class="subscription-input full-width-input" type="email" placeholder="Email" v-model="email" />
        </div>
         <div class="submit-button-wrapper">
        <button class="subscription-button full-width-input" @click="subscribe">Subscribe</button>
         </div>
      </div>
   </div>
  </section>
</template>

<script>
export default {
  name: 'Subscribe',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  methods: {
    subscribe() {
      console.log({firstName: this.firstName, lastName: this.lastName, email: this.email})
    }
  }
}
</script>

<style scoped>
.subscription-wrapper {
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.subscription-content {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.subscription-text {
  margin-right: 50px;
}

.subscription-form {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.subscription-form > div {
  flex: 1;
  margin-bottom: 20px;
}

.name-inputs {
  display: flex;
  justify-content: space-between;
}

.subscription-input {
  padding: 10px;
  font-size: 14px;
}

.name-input {
  flex: 1;
  margin-right: 10px;
}

.full-width-input {
  width: 100%;
  box-sizing: border-box;
}

.subscription-button {
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
  text-transform: uppercase;

}
.submit-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
}

.subscription-button:hover {
  background-color: darkblue;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 20px;
  gap: 20px;
}
</style>

