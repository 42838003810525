 <!-- Experience.vue -->
 <template>
   <div class="flex-container">
     <MicronVirginia/>
     <MicronSingapore/>
     <AmdSingapore/>
   </div>
 </template>

 <script>
import MicronVirginia from './MicronVirginia.vue'
import MicronSingapore from './MicronSingapore.vue'
import AmdSingapore from './AmdSingapore.vue'

export default {
   components: {
     MicronVirginia,
     MicronSingapore,
     AmdSingapore
   }
}
</script>

<style scoped>
.page-wrapper {
   display: flex;
   justify-content: space-between;
   padding: 20px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1em; 
  width: 60%;
  margin: 0 auto;
}
</style>

