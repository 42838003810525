<template>
  <form class="contact-form" @submit.prevent="handleSubmit">
    <div class="form-group">
      <label for="name">Name:</label>
      <input type="text" id="name" name="name" v-model="formData.name" required>
    </div>
    <div class="form-group">
      <label for="email">Email:</label>
      <input type="email" id="email" name="email" v-model="formData.email" required>
    </div>
    <div class="form-group">
      <label for="contact">Contact Number (Optional):</label>
      <input type="tel" id="contact" name="contact" v-model="formData.contact">
    </div>
    <div class="form-group">
      <label for="reason">Reason for Contact:</label>
      <textarea id="reason" name="reason" v-model="formData.reason" required></textarea>
    </div>
      <div class="form-group checkbox-inline">
       <span class="checkbox-label">Subscribe to Newsletters:</span>
             <input type="checkbox" id="subscribe" name="subscribe" v-model="formData.subscribe">
    </div>
    <button type="submit">Submit</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        contact: '',
        reason: '',
      },
    };
  },
  methods: {
    handleSubmit(event) {
      // Handle form submission logic here, e.g., send data to server
      console.log('Form submitted:', this.formData);
      event.target.reset(); // Reset the form after submission
    },
  },
};
</script>

<style scoped>
/* Add your desired styles here (optional) */

/* Contact form styles */
 .contact-form {
   display: flex;
   flex-direction: column;
   gap: 10px; /* Add spacing between form elements */
   width: 100%; /* Make form fit the content area */
   /* Center the form horizontally within the content area (optional) */
   margin: 0 auto;
 }

 .contact-form input,
 .contact-form textarea {
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 4px;
   width: 100%;
   font-size: 16px;
 }

 .contact-form button {
    padding: 10px 20px;
   background-color: blue;
   color: white;
   border: none;
   cursor: pointer;
   text-transform: uppercase;
 }

 .contact-form button:hover {
   background-color: #2980b9;
 }
.checkbox-inline {
  display: flex;
  align-items: center; /* Align label and checkbox vertically */
}

.checkbox-inline input[type="checkbox"] {
  margin-right: 5px; /* Add spacing between checkbox and label */
}

.checkbox-label {
  display: inline-block; /* Make the label behave like a block element */
  max-width: 150px; /* Optional: Set a maximum width for label text */
  white-space: nowrap; /* Prevent word wrapping within the label */
}
</style>

