<template>
  <div class="photo-grid">
    <div class="photo-wrapper" v-for="(src, index) in images" :key="index">
      <img class="photo" :src="src" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: []
    }
  },
  created() {
    const context = require.context("./assets", false, /\.(png|jpe?g|svg)$/);
    this.images = context.keys().map(context);
  }
};
</script>

<style scoped>
.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  padding: 20px 0;
}

.photo-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.photo {
  object-fit: cover;   
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>

