<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Subscribe/> 
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Subscribe from './components/Subscribe.vue'
export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Subscribe
  }
}
</script>

<style>
/* Add global CSS here */

.router-link-active, .router-link-exact-active, .router-link {
  color: #inherit !important;             
}
a.router-link-active {
  color: #inherit !important; 
}

img.fixed-size {
   width: 600px;  /* or any size you want */
   height: 400px; /* or any size you want */
   object-fit: cover; /* This will make sure your image is scaled correctly */
          
}

</style>

