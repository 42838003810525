 <!-- Experience.vue -->
 <template>
   <div class="flex-container">
     <WorldRecord/>
   </div>
 </template>

 <script>
import WorldRecord from './WorldRecord.vue'
export default {
   components: {
     WorldRecord
   }
}
</script>

<style scoped>
.page-wrapper {
   display: flex;
   justify-content: space-between;
   padding: 20px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1em; 
  width: 60%;
  margin: 0 auto;
}
</style>

