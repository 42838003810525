<template>
  <div class="flex-container">
    <div v-for="topic in topics" :key="topic.header" class="topic">
      <div class="content">
        <div>
          <h2>{{ topic.header }}</h2>
          <h3>{{ topic.subheader }}</h3>
          <p>{{ topic.description }}</p>
        </div>
      </div>
      <img class = "photo fixed-size component-image"  :src="topic.image" alt="topic image">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topics: [
        {
            header: 'Smart Flexi Mattress',
            subheader: '18th March 2024',
            description: 'description about smart flex mattress is here',
            document: require('./assets/design_patent_UK_justfiled_smart_flexi_matress.png'),
            image: require('./assets/cmg_soon.jpg')
        },
        {
            header: 'Microwave Sensor Based Soil Analyzer',
            subheader: '12th March 2024',
            description: 'detail about this micronwave sensor',
            document: '/files/Design_UK_microsensor_6350946.pdf',
            image: require('./assets/cmg_soon.jpg')
        },
         {
             header: 'AI-IOT Device For Manfacturing Defauct Prevention in Semiconductor - Chip',
             subheader: '14th March 2024',
             description: 'Detail about this patent',
             document: '/files/Indian_design_patent_semicon_AI_IOT.pdf',
             image: require('./assets/cmg_soon.jpg')
         },
         {
             header: 'IOT Based Lung Cancer Detection usind deep learning techniques',
             subheader: '1st March 2024',
             description: 'Details about lung cancer detection',
             document: '/files/Indian_Utility_202421015410.pdf',
             image: require('./assets/cmg_soon.jpg')
         },
         {
              header: 'Smart Device to predict and measure the acction to medication and a method forending addiction',
              subheader: '29th Sep 2023',
              description: 'details about this ',
              document: '/files/Indian_Utility_patent_1.pdf',
              image: require('./assets/cmg_soon.jpg')
          }

        // ... more topics
      ]
    }
  }
}
</script>

<style scoped>
.topic {
  display: flex;
  justify-content: space-between;
}

 .flex-container {
   display: flex;
   flex-direction: column;
   gap: 1em;
   width: 60%;
   margin: 0 auto;
 }
.component-image {
    margin: 20px;  /* Adjust as needed */
  }

.btn {
  background: blue;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  margin-top: 10px;
}

img {
  max-width: 200px;
  width:200px;
  height: 300px;
}
</style>
