 <!-- Experience.vue -->
 <template>
   <div class="flex-container">
     <PMP/>
     <CSM/>
     <LSSBB/>
   </div>
 </template>

 <script>
import PMP from './PMP.vue'
import CSM from './CSM.vue'
import LSSBB from './LSSBB.vue'
export default {
   components: {
     PMP,
     CSM,
     LSSBB
   }
}
</script>

<style scoped>
.page-wrapper {
   display: flex;
   justify-content: space-between;
   padding: 20px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1em; 
  width: 60%;
  margin: 0 auto;
}
</style>

