 <template>
    <div class="flex-container">
      <ContactMe/>
    </div>
  </template>

  <script>
 import ContactMe from './ContactMe.vue'
 export default {
    components: {
      ContactMe
    }
 }
 </script>

 <style scoped>
 .page-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
 }
 .flex-container {
   display: flex;
   flex-direction: column;
   gap: 1em;
   width: 60%;
   margin: 0 auto;
 }
 </style>

