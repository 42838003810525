 <!-- Experience.vue -->
 <template>
   <div class="flex-container">
     <LTV/>
     <CHASSPASS/>
     <GSAWomen/>
     <WAT/>
   </div>
 </template>

 <script>
import LTV from './LTV.vue'
import CHASSPASS from './CHAASPASS.vue'
import GSAWomen from './GSAWomen.vue'
import WAT from './WAT.vue'
export default {
   components: {
     LTV,
     CHASSPASS,
     GSAWomen,
     WAT
   }
}
</script>

<style scoped>
.page-wrapper {
   display: flex;
   justify-content: space-between;
   padding: 20px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1em; 
  width: 60%;
  margin: 0 auto;
}
</style>

