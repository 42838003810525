import { render, staticRenderFns } from "./CHAASPASS.vue?vue&type=template&id=4b29d434&scoped=true"
var script = {}
import style0 from "./CHAASPASS.vue?vue&type=style&index=0&id=4b29d434&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b29d434",
  null
  
)

export default component.exports