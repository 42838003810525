<template>
  <section class="section-wrapper">
    <div class="basic-info">
    <h1 class="header">{{ name }}</h1>
    <p class="subheader">{{ quote }}</p>
    <div class="content">
      <img :src="photoUrl" class="photo" alt="My Photo">
      <div class= "description">
      <p>{{ description1 }}</p>
      <p>{{ description2  }}</p>
      </div>
    </div>
    <div class="social-media">
      <!-- Replace with the actual components or URLs of your social media icons -->
      <a href="https://www.linkedin.com/in/knowrupal/" target="_blank"><img src="./assets/linkedin_icon.svg" alt="LinkedIn"></a>
      <a href="https://twitter.com/RupalJa5366118" target="_blank"><img src="./assets/x_icon.svg" alt="Twitter"></a>
    </div>
  </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: 'Rupal Jain',
      quote: 'Change is the only Constant ~ Heraclitus',
      photoUrl: require('./assets/rupal_dp.jpeg'),
      description1: 'I am an accomplished Engineer, PMI certified PMP®, Scrum alliance certified CSM®, Program Manager, published author, and patent holder, celebrated for catalysing transformative changes in electrical/electronics engineering, semiconductors, and production operations.',
      description2: 'My expertise lies in resolving intrinsic and extrinsic challenges, adeptly managing end-to-end projects encompassing change management, quality assurance, product engineering, technology transfer, failure analysis, and lean six sigma management, which is helping me to mark a distinguishing career by international recognition, with invitations to prestigious publications like the GSA Women\'s Leadership Initiative, Women Adore Tech, and national magazines.'
    }
  }
}
</script>
<style scoped>
.basic-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px;
  padding: 1rem;
  width: 60%;
  max-width: 60%;  /* you can adjust this value as needed */
  box-sizing: border-box;
  margin: 1in auto;
  background: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
}

.photo {
  height: 80%;
  width: 20%;
  margin-right: 1rem;
  object-fit: cover;
}

.social-media img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.section-wrapper {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}
.basic-info-button {
    background-color: #3498db;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.basic-info-button:hover {
    background-color: #2980b9;
}
</style>
