<!-- Home.vue -->
<template>
  <div class="page-wrapper">
    <BasicInfo/>
  </div>
</template>

<script>
import BasicInfo from './BasicInfo.vue'

export default {
  components: {
    BasicInfo
  }
}
</script>

<style scoped>
.page-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
</style>

