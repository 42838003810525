 <!-- Experience.vue -->
 <template>
   <div class="flex-container">
     <LeadershipFederation/>
     <AdpMentor/>
     <Ieee/>
     <Issip/>
     <Wit/>
     <AdaniBoard/>
   </div>
 </template>

 <script>
import LeadershipFederation from './LeadershipFederation.vue'
import AdpMentor from './AdpMentor.vue'
import AdaniBoard from './AdaniBoard.vue'
import Ieee from './Ieee.vue'
import Issip from './Issip.vue'
import Wit from './Wit.vue'

export default {
   components: {
     LeadershipFederation,
     AdpMentor,
     AdaniBoard,
     Ieee,
     Issip,
     Wit

   }
}
</script>

<style scoped>
.page-wrapper {
   display: flex;
   justify-content: space-between;
   padding: 20px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 1em; 
  width: 60%;
  margin: 0 auto;
}
</style>

