import { render, staticRenderFns } from "./BE.vue?vue&type=template&id=b3123b78&scoped=true"
var script = {}
import style0 from "./BE.vue?vue&type=style&index=0&id=b3123b78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3123b78",
  null
  
)

export default component.exports