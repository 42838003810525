<template>
  <header class="header-bar">
    <nav>
      <ul class="nav-links">
        <li><router-link to="/home">Home</router-link></li>
        <li><router-link to="/associations">Associations</router-link></li>
         <li><router-link to="/media">Media</router-link></li>
         <li><router-link to="/speaking_engagements">Speaking Engagements</router-link></li>
        <li 
          v-for="dropdown in dropdowns" 
          :key="dropdown.name" 
          class="dropdown"
          @mouseover="toggleDropdown(dropdown.name, true)"
          @mouseleave="toggleDropdown(dropdown.name, false)"
        >
          {{ dropdown.name }}
          <ul v-if="dropdown.visible" class="dropdown-content">
            <li v-for="item in dropdown.items" :key="item">
              <router-link :to="`/${item.path}`">{{ item.name  }}</router-link>
            </li>
          </ul>
        </li>
        <!-- Add your regular links here -->
        <li><router-link to="/experience">Experience</router-link></li>
        <li><router-link to="/education">Education</router-link></li>
        <li><router-link to="/team_impact">Team Impact & Collaboration</router-link></li>
        <li><router-link to="/contact_me">Contact Me</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      dropdowns: [
        { 
          name: 'Author', 
          visible: false,
          items: [
              { name: 'Books', path: 'books'  },
              { name: 'Articles', path: 'articles'   }
          ]
        },
        {
           name: 'Outstanding Achievements',
           visible: false,
           items: [
              { name: 'Awards', path: 'awards'   },
              { name: 'Patents', path: 'patents'   },
              { name: 'Certifications', path: 'certifications'   },
              { name: 'Grants & Fellowships', path: 'grants'   },
              { name: 'Recognitions', path: 'recognitions'   }
          ]
         }
      ],
      showModal: false,
    }
  },
  methods: {
    toggleDropdown(name,shouldShow){
      this.dropdowns = this.dropdowns.map(drop => {
        if (drop.name === name) drop.visible = shouldShow;
        return drop;
      });
    }
  }
};
</script>

<style scoped>
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  background-color: #333;
  list-style-type: none;
  color: #fff;
  padding: 15px; /* Padding for the dropdown */
   margin-top: 0; /* Zero margin at the top */
  width: 50%; /* Adjust width accordingly */

}
.dropdown-content li {
  margin-bottom: 10px; /* Margin at the bottom of each dropdown item */
}
.dropdown:hover .dropdown-content {
  display: block;
}
.header-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #333; 
  color: #fff;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
  padding: 0;
}
header {
  color: #fff !important;
      
}
header a,
header a:visited {
color: #fff !important;
text-decoration: none !important; 
               
}
</style>

