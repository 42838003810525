<template>
  <div class="component">
    <div class="component-row">
      <div class="content">
        <h2>Connect with Me</h2>
        <p>
          I'm available for:
          <ul>
            <li>Webinars</li>
            <li>Mentorship</li>
            <li>Judging Panels</li>
            <li>Consultations (Semiconductors & Project Management)</li>
          </ul>
        </p>
        <contact-form @submit="handleSubmit" />
      </div>
      <img class="photo fixed-size component-image" src="./assets/rupal_dp.jpeg" alt="photo" />
    </div>
  </div>
</template>

<script>
import ContactForm from './ContactForm.vue'; // Import your ContactForm component

export default {
  components: {
    ContactForm,
  },
  methods: {
    handleSubmit(data) {
      // Handle form submission logic here, e.g., send data to server
      console.log('Form submitted:', data);
    },
  },
};
</script>

<style scoped>
/* Main component styles (adjust as needed) */
.component-row {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center content and image vertically */
  /* Adjust spacing between content and image (optional) */
  padding: 20px;
  margin: 0 100px;
}

.component .content {
  flex: 1 1 auto;
  order: 1;
  word-wrap: break-word;
}

.component .photo {
  flex: 0 0 100px;
  max-width: 50%;
  order: 2;
  padding: 40px;
  border-radius: 50%; /* Round image corners */
}

.component-image {
  object-fit: cover;
  width: calc(100% - 80px);
  height: auto;
}
</style>

