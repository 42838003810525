import { render, staticRenderFns } from "./AIDTM.vue?vue&type=template&id=70d8dccb&scoped=true"
var script = {}
import style0 from "./AIDTM.vue?vue&type=style&index=0&id=70d8dccb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70d8dccb",
  null
  
)

export default component.exports